import {
  IStyle,
  IStackTokens,
  mergeStyleSets,
  getScreenSelector,
  ScreenWidthMinSmall,
  ScreenWidthMaxSmall,
  ScreenWidthMinMedium,
  ScreenWidthMaxMedium,
  ScreenWidthMinLarge,
  ScreenWidthMaxLarge,
  ScreenWidthMinXLarge,
  ScreenWidthMaxXLarge,
} from '@fluentui/react';

export const ownerDivStyle = mergeStyleSets({
  root: {
    width:'49%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between', //space-evenly,space-around
    marginLeft: '1%',
    marginRight: '20%',
    selectors: {
      [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        marginRight: '8%',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginRight: '8%',
      },
    },
  } as IStyle,

  hdivStyle: {
    alignItems: 'flex-start',
    margin: '1%',
  } as IStyle,

  hTextStyle: {
    fontSize: 18,
    fontWeight: 500,
  } as IStyle,
});
export const ownerStackTokens: IStackTokens = {
  childrenGap: 0,
};
