import {IImageProps} from '@fluentui/react';
import {recordNotFound} from '../../../assets';
import {IStyle, mergeStyleSets} from '@fluentui/react';
import {SParkTheme} from '../../../theme';

export const recordNotFoundStyles = (isError: boolean) =>
  mergeStyleSets({
    textStyle: {
      paddingBottom: 20,
      color: isError ? SParkTheme?.palette.red : SParkTheme?.palette.themeSecondary,
    } as IStyle,
  });
export const notFoundimageProps: IImageProps = {
  src: recordNotFound,
  height: 50,
  alt: 'No Record Found Icon',
};
