import {useEffect} from 'react';
import {ConfigKey, emptyString} from '../../../../configs';
import {ICarpoolRegisteredProps} from '.';
import {
  regionId,
  useGetCarpoolQuery,
  useGetConfigQuery,
  useGetCarpoolAdminQuery,
} from '@microsoft/smart-parking-coreui';

export const CarpoolRegistered = ({registrationId, updateCarpoolStatus, fromAdmin}: ICarpoolRegisteredProps) => {
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const {data: adminData, isSuccess: isAdminSuccess} = useGetCarpoolAdminQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      regionId: regionId,
      carpoolApiCreateGroupUrlAdmin: configData[ConfigKey.CreateCarpoolGroupAdminUrl] as string,
      registrationId: registrationId,
    },
    {
      skip: registrationId === undefined || registrationId === emptyString || !fromAdmin,
    },
  );
  const {data: userData, isSuccess: isUserSuccess} = useGetCarpoolQuery(
    {
      carpoolApiBaseUrl: configData[ConfigKey.CarpoolUrl] as string,
      regionId: regionId,
      carpoolApiCreateGroupUrl: configData[ConfigKey.CreateCarpoolGroupUrl] as string,
      registrationId: registrationId,
    },
    {
      skip: registrationId === undefined || registrationId === emptyString || fromAdmin,
    },
  );
  const {data, isSuccess} = fromAdmin
    ? {data: adminData, isSuccess: isAdminSuccess}
    : {data: userData, isSuccess: isUserSuccess};
  useEffect(() => {
    isSuccess && data ? updateCarpoolStatus(true, registrationId) : updateCarpoolStatus(false, registrationId);
  }, [data, isSuccess, registrationId, updateCarpoolStatus]);

  return <></>;
};
