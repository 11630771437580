import {IStyle, IStackStyles} from '@fluentui/react';

export const policyCheckBoxStyles: IStackStyles = {
  root: {
    alignItems: 'flex-start',
    marginLeft: '1%',
    marginRight: '20%',
    marginTop: 10,
  } as IStyle,
};
