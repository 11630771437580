import {FontSizes, FontWeights, IStyle, hiddenContentStyle, mergeStyleSets, mergeStyles} from '@fluentui/react';

export const DataGridStyles = mergeStyleSets({
  rootDiv: {
    overflow: 'auto',
    selectors: {
      h1: {
        fontSize: '26px',
        fontWeight: FontWeights.bold,
        margin: 0,
        lineHeight: '32px',
      },
      h2: {
        fontSize: FontSizes.size18,
        fontWeight: FontWeights.bold,
        lineHeight: '24px',
        margin: '50px 0 4px 0',
      },
      h3: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
        lineHeight: '20px',
        margin: '24px 0 0 0',
      },
      p: {
        fontSize: FontSizes.size14,
        lineHeight: '20px',
        margin: 0,
      },
      ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
      },
      '#additionalResources > p': {
        marginTop: '24px',
      },
    },
  } as IStyle,
  divPadding: {
    paddingTop: '20px',
    paddingBottom: '30px',
  } as IStyle,
  spinnerStyle: {
    padding: '4%',
  } as IStyle,
  okButtonStyle: {
    marginRight: '10px',
  } as IStyle,
});
export const gridDialogStyles = {main: {maxWidth: 450}};
export const gridContentStyles = {title: {fontSize: 25}};
export const screenReaderOnly = mergeStyles(hiddenContentStyle);
