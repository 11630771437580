import {IFormState, RootState} from '@microsoft/smart-parking-coreui';
import React from 'react';
import {useSelector} from 'react-redux';
import {OwnerDetails} from '../OwnerDetails';
import {PolicyCheck} from '../PolicyCheck';
import {VehicleDetails} from '../VehicleDetails';
import { IRegistrationFormProps } from './RegistrationForm.types';

export const RegistrationForm = ({isFormAdmin}:IRegistrationFormProps): JSX.Element => {
  const isEditFormState: IFormState = useSelector((state: RootState) => state.formState);
  const isDisablePolicy = isEditFormState.isFormEdit;
  return (
    <>
      <VehicleDetails isFormAdmin={isFormAdmin} />
       <OwnerDetails />
       <br />
      {!isDisablePolicy ? <PolicyCheck /> : null}
    </>
  );
};
