import {Image, Stack, Text} from '@fluentui/react';
import {useEffect} from 'react';
import {RecordNotFoundUsageEvents} from '../../../configs/usageEvents';
import Config from '../../../configs/constants/uiConfig';
import {notFoundimageProps, recordNotFoundStyles, IRecordNotFound} from '.';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';
import {TelemetryConstants} from '../../../configs';

export const RecordNotFound: React.FunctionComponent<IRecordNotFound> = props => {
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(RecordNotFoundUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(RecordNotFoundUsageEvents.root);
    };
  }, [telemetryClient]);

  return (
    <Stack horizontalAlign="center" aria-label="No Record found">
      <Image {...notFoundimageProps} />
      <Text className={recordNotFoundStyles(props.isError).textStyle} variant="medium">
        {props.message}
      </Text>
    </Stack>
  );
};
