import {CoherenceTheme} from '@coherence-design-system/styles';
import {createTheme, ITheme, getTheme as getFluentTheme} from '@fluentui/react';

import {ISParkTheme} from './Theme.types';

const parkingTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#4A4A4A',
    themeSecondary: '#0a0a0a',
    themeDark: '#121212',
    themeLighterAlt: '#f8f8f8',
    themeLighter: '#f2f2f2',
    themeLight: '#ededed',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#757575',
    white: '#ffffff',
    blueDark: '#0000FF',
    blueMid: '#1F69FF',
    blue: '#0078d4',
    blueLight: '#0AA9DD',
    red: '#e50000',
    redDark: '#6B1518',
    green: '#56810E',
  },
  defaultFontStyle: {
    fontFamily: 'SegoeUI-light, Helvetica, Arial, serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  fonts: {
    small: {
      fontSize: 10,
      fontWeight: 100,
    },
    smallPlus: {
      fontSize: 12,
      fontWeight: 100,
    },
    medium: {
      fontSize: 14,
      fontWeight: 100,
    },
    mediumPlus: {
      fontSize: 16,
      fontWeight: 100,
    },
    large: {
      fontSize: 30,
      fontWeight: 100,
    },
    xLarge: {
      fontSize: 40,
      fontWeight: 100,
    },
  },
});

export const SParkTheme: ITheme = CoherenceTheme;

Object.assign(CoherenceTheme.palette, parkingTheme.palette);
Object.assign(CoherenceTheme.fonts, parkingTheme.fonts);

export function getTheme(): ISParkTheme {
  return getFluentTheme() as ISParkTheme;
}
