import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IAppUsageEvents {
  fetchGraphDetails: UserEvent;
  fetchAuthToken: UserEvent;
}

export const AppUsageEvents: IAppUsageEvents = {
  fetchGraphDetails: {
    subFeature: 'SPark.App.Load.fetchGraphDetails',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  fetchAuthToken: {
    subFeature: 'SPark.App.Load.fetchAuthToken',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
};
