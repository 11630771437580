import {IComboBoxOption} from '@fluentui/react';
import { ICarpoolServiceModel, IIsCarpoolError } from '@microsoft/smart-parking-coreui';
import {emptyString} from '../configs';

export const optionsCarPoolData: IComboBoxOption[] = [
  {key: 0, text: 'MS Employee'},
  {key: 1, text: 'Non MS Employee'},
  {key: 2, text: 'Scoop Employee'},
];
export const mockCarpoolServiceData: ICarpoolServiceModel = {
  licensePlate: 'ABC123',
  groupId: '1234',
  groupOwnerAlias: 'test',
  id: 'test',
  carpoolMembers: [
    {
      memberType: 'MS Employee',
      memberAlias: 'test',
      originCity: 'Hyderabad',
      permitNumber: 'I1234',
      email: emptyString,
    },
  ],
};

export const mockIsCarpoolError: IIsCarpoolError = {
  isCarpoolError: false,
};
