import {EventType, UsageEventName, UserEvent} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IRegisterButtonUsageEvents {
  root: string;
  CreateRegistrationSuccess: UserEvent;
  CreateRegistrationFailure: UserEvent;
  DuplicateRegistrationFailure: UserEvent;
  CreateCarpoolEmailSuccess: UserEvent;
  CreateCarpoolEmailFailure: UserEvent;
  CreateCarpoolRegistrationSuccess: UserEvent;
  CreateCarpoolRegistrationFailure: UserEvent;
}

export const RegisterButtonUsageEvents: IRegisterButtonUsageEvents = {
  root: 'SPark.Registration.RegisterButton',
  CreateRegistrationSuccess: {
    subFeature: 'SPark.Registration.RegisterButton.Create.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  CreateRegistrationFailure: {
    subFeature: 'SPark.Registration.RegisterButton.Create.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DuplicateRegistrationFailure: {
    subFeature: 'SPark.Registration.Create.duplicate',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  CreateCarpoolEmailSuccess: {
    subFeature: 'SPark.Carpool.RegisterButton.CarpoolEmail.Create.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  CreateCarpoolEmailFailure: {
    subFeature: 'SPark.Carpool.RegisterButton.CarpoolEmail.Create.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  CreateCarpoolRegistrationSuccess: {
    subFeature: 'SPark.Carpool.RegisterButton.CarpoolRegistration.Create.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  CreateCarpoolRegistrationFailure: {
    subFeature: 'SPark.Carpool.RegisterButton.CarpoolRegistration.Create.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
};
