import {IStyle, mergeStyleSets} from '@fluentui/react';
import {dataGrid} from '../../../../configs';
import {SParkTheme} from '../../../../theme';

export const carpoolStyle = mergeStyleSets({
  carpoolDivStyle: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
    marginLeft: '1%',
    marginRight: '5%',
  } as IStyle,
  carpoolDropdownStyle: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
    marginLeft: '1%',
    marginRight: '50%',
    marginBottom: '10px',
  } as IStyle,
  deleteIconStyle: {
    marginTop: 30,
    color: SParkTheme.palette?.red,
    displayName: dataGrid.gridColumnActionConst.deleteAction.visibleActions.iconDisplayName,
    hover: dataGrid.gridColumnActionConst.deleteAction.visibleActions.hover,
  } as IStyle,
});
