import {Label, Icon, Stack} from '@fluentui/react';
import {IIconLabel, IconLabelStyles, stackTokens} from '..';

export const IconLabelRenderer = (props: IIconLabel): JSX.Element => {
  const {root, labelStyle} = IconLabelStyles;
  return (
    <>
      <Stack horizontal verticalAlign="center" tokens={stackTokens}>
        <Label className={labelStyle}>{props.label}</Label>
        <Icon iconName={props.icon} title={props.title} className={root} tabIndex={0} />
      </Stack>
    </>
  );
};
