import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface ICarpoolDetailUsageEvents {
  SelectCarpoolGroupMember: UserEvent;
  InputCarpoolGroupMemberAlias: UserEvent;
  InputCarpoolGroupMemberEmail: UserEvent;
  InputCarpoolGroupMemberOriginCity: UserEvent;
  root: string;
}

export const CarpoolDetailUsageEvents: ICarpoolDetailUsageEvents = {
  SelectCarpoolGroupMember: {
    subFeature: 'SPark.Registration.Carpool.Detail.GroupMember.SelectBox.onChange',
    type: EventType.User,
    eventName: UsageEventName.DropdownSelected,
  },
  InputCarpoolGroupMemberAlias: {
    subFeature: 'SPark.Registration.Carpool.Detail.GroupMember.Alias.onChange',
    type: EventType.User,
    eventName: UsageEventName.TextChanged,
  },
  InputCarpoolGroupMemberEmail: {
    subFeature: 'SPark.Registration.Carpool.Detail.GroupMember.Email.onChange',
    type: EventType.User,
    eventName: UsageEventName.TextChanged,
  },
  InputCarpoolGroupMemberOriginCity: {
    subFeature: 'SPark.Registration.Carpool.Detail.GroupMember.OriginCity.onChange',
    type: EventType.User,
    eventName: UsageEventName.TextChanged,
  },
  root: 'SPark.Registration.Carpool.Detail',
};
