import {MessageBar} from '@fluentui/react';
import {useEffect} from 'react';
import {MessageBannerUsageEvents} from '../../../configs/usageEvents';
import Config from '../../../configs/constants/uiConfig';
import {IMessageBannerProps} from './MessageBanner.types';
import {TelemetryConstants} from '../../../configs';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';

export const MessageBanner = (props: IMessageBannerProps): JSX.Element => {
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(MessageBannerUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(MessageBannerUsageEvents.root);
    };
  }, [telemetryClient]);

  return (
    <>
      <MessageBar
        aria-live="polite"
        tabIndex={0}
        role="alert"
        className={props.className}
        messageBarIconProps={props.messageBarIconProps}
        messageBarType={props.messageBarType}
        onDismiss={props.onDismiss}
        dismissIconProps={props.dismissIconProps}
        dismissButtonAriaLabel="Close">
        {props.children}
      </MessageBar>
    </>
  );
};
