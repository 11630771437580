import {mergeStyles} from '@fluentui/merge-styles';
import {ITextFieldStyleProps, ITextFieldStyles} from '@fluentui/react';

export const inputStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  return {
    root: {
      width: '160px',
    },
  };
};

export const buttonStyle = {
  margin: '10.7% 0 0 1.5%',
};

export const searchBarStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
  '@media (max-width: 480px)': {
    flexDirection: 'column',
    margin: '4% 0 0 0',
  },
  justifyContent: 'space-between',
  margin: '0 3.25% 0 0',
});

export const adminSearchPanelStyle = mergeStyles({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
  margin: '2% 1% 2% 1%',
});
