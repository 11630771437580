import {Spinner} from '@fluentui/react';
import {useEffect} from 'react';
import {SpinLoaderUsageEvents} from '../../../configs/usageEvents';
import Config from '../../../configs/constants/uiConfig';
import {ISpinLoaderprops} from '.';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';
import {TelemetryConstants} from '../../../configs';

export const SpinLoader = ({size, className, label}: ISpinLoaderprops) => {
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(SpinLoaderUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(SpinLoaderUsageEvents.root);
    };
  }, [telemetryClient]);
  return <Spinner size={size} className={className} label={label} />;
};
