import {CarpoolDetail, CarpoolValidationError} from '..';
import {useDispatch, useSelector} from 'react-redux';
import {EmptySpaceString, carpool, emptyString} from '../../../../configs';
import {isValidEmail} from '../../../../utils';
import {ICarpoolMemberListProps} from '.';
import {
  ICarpoolData,
  RootState,
  IRegisterVehicleErrorItem,
  setCarpoolMemberDetail,
  ICarpoolMember,
  setRegisterFormState,
  IFormState,
} from '@microsoft/smart-parking-coreui';

export const CarpoolMemberList = (props: ICarpoolMemberListProps): JSX.Element => {
  const dispatch = useDispatch();
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const {isFormSubmit}: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const isEditFormState: IFormState = useSelector((state: RootState) => state.formState);
  const handleInputChange = (inputValue: string, fieldName: any, options: any, fieldIndex: number) => {
    let fieldValue = options && options.text ? options.text : inputValue;
    dispatch(setRegisterFormState({...isEditFormState, isAnyFieldEdited: true}));
    dispatch(
      setCarpoolMemberDetail({
        index: fieldIndex,
        formName: fieldName,
        formValue: fieldValue,
      }),
    );
    if (fieldName === carpool.alias.name) {
      dispatch(
        setCarpoolMemberDetail({
          index: fieldIndex,
          formName: carpool.email.name,
          formValue: emptyString,
        }),
      );
    }
    if (fieldName === carpool.email.name) {
      dispatch(
        setCarpoolMemberDetail({
          index: fieldIndex,
          formName: carpool.alias.name,
          formValue: emptyString,
        }),
      );
    }
  };

  return (
    <>
      {carpoolDetails.carpoolMembers.map((carpoolMember: ICarpoolMember, index: number) => {
        let errors: CarpoolValidationError = {
          memberType: carpoolMember.memberType ? emptyString : EmptySpaceString,
          memberAlias: carpoolMember.memberAlias ? emptyString : EmptySpaceString,
          originCity: carpoolMember.originCity ? emptyString : EmptySpaceString,
          email: isValidEmail(carpoolMember.email) ? emptyString : EmptySpaceString,
        };
        return (
          <CarpoolDetail
            key={index}
            carpoolData={carpoolMember}
            handleInputChange={handleInputChange}
            index={index}
            carpoolError={isFormSubmit ? errors : {}}
            carpoolMemberAreaRef={props.carpoolMemberAreaRef ?? undefined}
          />
        );
      })}
    </>
  );
};
