import {IStyle, mergeStyleSets, IImageProps, Depths} from '@fluentui/react';
import {myHubAndroidQr, myHubIosQr} from '../../../assets';

export const awarenessSectionStyles = mergeStyleSets({
  root: {
    padding: 10,
    backgroundColor: '#eeeeee',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    gap: 50,
    boxShadow: Depths.depth8,
    marginBottom: "2.5%"
  } as IStyle,
  textSection: {
    flexBasis: '50%',
    alignSelf: 'center',
    lineHeight: 25,
  } as IStyle,

  qrCodeSection: {
    display: 'flex',
    flexBasis: '35%',
    flexDirection: 'row',
    gap: 50,
    marginLeft: 'auto',
  } as IStyle,
  headingTag: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
  } as IStyle,
});

const ImageStyleProps = {
  height: 80,
};

export const myHubAndroidQrImageProps: IImageProps = {
  src: myHubAndroidQr,
  alt: 'Android QR Code Icon',
  ...ImageStyleProps,
};
export const myHubIosQrImageProps: IImageProps = {
  src: myHubIosQr,
  alt: 'iOS QR Code Icon',
  ...ImageStyleProps,
};
