import {Label, Image, MessageBarType} from '@fluentui/react';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';
import {useEffect} from 'react';
import {infoBadgeStyles, hiddeniconPropsStyle, leftIconProps, closeIconProps, InfoBadgeProps} from '.';
import {FullWidthContainer, MessageBanner, validateIconProps} from '../..';
import {TelemetryConstants} from '../../../configs';
import Config from '../../../configs/constants/uiConfig';
import {InfoBadgeUsageEvents} from '../../../configs/usageEvents';

export const InfoBadge = ({messageText, messageStatus, onDismiss}: InfoBadgeProps): JSX.Element => {
  const {messageStyle, messageStyleError, labelDivStyle, childDiv, imageStyle, errorHeadingStyle, closeImageStyle} =
    infoBadgeStyles;
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(InfoBadgeUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(InfoBadgeUsageEvents.root);
    };
  }, [telemetryClient]);

  return (
    <FullWidthContainer>
      <MessageBanner
        className={messageStatus === 'success' ? messageStyle : messageStyleError}
        messageBarType={messageStatus === 'success' ? MessageBarType.success : MessageBarType.error}
        onDismiss={onDismiss}
        dismissIconProps={hiddeniconPropsStyle}
        messageBarIconProps={hiddeniconPropsStyle}>
        <div className={childDiv}>
          <Image {...(messageStatus === 'success' ? leftIconProps : validateIconProps)} className={imageStyle} />
          <div className={labelDivStyle}>
            <Label className={errorHeadingStyle}> {messageText}</Label>
          </div>
          <Image {...closeIconProps} className={closeImageStyle} onClick={onDismiss} />
        </div>
      </MessageBanner>
    </FullWidthContainer>
  );
};
