import React from 'react';
import {Link, Image, Text} from '@fluentui/react';
import {imageProps} from './SiteHeader.styles';
import {siteHeaderStyles} from './SiteHeader.styles';
import {siteHeaderLabel} from '../../../configs';

export const SiteHeaderLabel: React.FunctionComponent = () => {
  const {siteHeaderImage, textStyle} = siteHeaderStyles;
  return (
    <div className={siteHeaderImage}>
      <Link name={siteHeaderLabel.linkName} href="/">
        <div>
          <Image {...imageProps} alt={siteHeaderLabel.imageAlt} />
        </div>
      </Link>
      <Text className={textStyle}>{siteHeaderLabel.headerLabel}</Text>
    </div>
  );
};
