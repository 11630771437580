import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IEditCarpoolUsageEvents {
  EditCarpoolSuccess: UserEvent;
  EditCarpoolFailure: UserEvent;
  CreateCarpoolRegistrationSuccess: UserEvent;
  CreateCarpoolRegistrationFailure: UserEvent;
  DeleteCarpoolRegistrationSuccess: UserEvent;
  DeleteCarpoolRegistrationFailure: UserEvent;
  SendCarpoolEmailSuccess: UserEvent;
  SendCarpoolEmailFailure: UserEvent;
}

export const EditCarpoolUsageEvents: IEditCarpoolUsageEvents = {
  EditCarpoolSuccess: {
    subFeature: 'SPark.Registration.Carpool.Edit.success',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  EditCarpoolFailure: {
    subFeature: 'SPark.Registration.Carpool.Edit.failure',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  CreateCarpoolRegistrationSuccess: {
    subFeature: 'SPark.Carpool.CreateCarpoolRegistration.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  CreateCarpoolRegistrationFailure: {
    subFeature: 'SPark.Carpool.CreateCarpoolRegistration.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DeleteCarpoolRegistrationSuccess: {
    subFeature: 'SPark.Carpool.DeleteCarpoolRegistration.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DeleteCarpoolRegistrationFailure: {
    subFeature: 'SPark.Carpool.DeleteCarpoolRegistration.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  SendCarpoolEmailSuccess: {
    subFeature: 'SPark.Carpool.SendCarpoolEmail.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  SendCarpoolEmailFailure: {
    subFeature: 'SPark.Carpool.SendCarpoolEmail.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
};
