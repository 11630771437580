import {IStyle, mergeStyleSets} from '@fluentui/react';
import {SParkTheme} from '../../../theme';

export const registerButtonStyle = mergeStyleSets({
  root: {
    marginTop: '20px',
    marginLeft: '1%',
    marginRight: '20%',
    marginBottom: '20px',
    padding: 20,
    fontFamily: `${SParkTheme.fonts.xxLarge}`,
  } as IStyle,
});
