import {ComboBox, getTheme, IComboBox} from '@fluentui/react';
import React, {useEffect} from 'react';
import {emptyString, TelemetryConstants} from '../../../configs';
import {SelectBoxUsageEvents} from '../../../configs/usageEvents';
import Config from '../../../configs/constants/uiConfig';
import {ISParkTheme} from '../../../theme';
import {IconLabelRenderer} from '../IconLabel';
import {getCaretStyles, ContainerComboBoxStyles, ISelectBoxProps, ISelectBoxOption, optionsWithCustomStyling} from '.';
import {getTelemetryClient} from '@microsoft/smart-parking-coreui';
export function SelectBox<T>(props: ISelectBoxProps<T>): JSX.Element {
  const [filteredOptions, setFilteredOptions] = React.useState<ISelectBoxOption<T>[]>([]);
  const [optionText, setOptionText] = React.useState<string>(emptyString);
  const {options, selectedKey, text, componentRef} = props;
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(SelectBoxUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(SelectBoxUsageEvents.root);
    };
  }, [telemetryClient]);

  useEffect(() => {
    setFilteredOptions(options);
    const selectedOption = options.find(option => option.key === selectedKey);
    if (selectedOption) {
      setOptionText(selectedOption.text);
    } else if (text) setOptionText(text);
    else {
      setOptionText(emptyString);
    }
  }, [options, selectedKey, text]);

  const comboBox = (componentRef as React.RefObject<IComboBox>) ?? React.createRef<IComboBox>();
  const onComboBoxFocus = React.useCallback((): void => {
    if (comboBox && comboBox.current) {
      comboBox.current.focus(true);
    }
    return;
  }, [comboBox]);

  const theme: ISParkTheme = (props.theme ?? (getTheme() as unknown)) as ISParkTheme;
  const caretDownButtonStyle = getCaretStyles(theme);
  return (
    <div>
      <ComboBox
        {...props}
        options={optionsWithCustomStyling(filteredOptions)}
        styles={ContainerComboBoxStyles(options.length)}
        required={props.required}
        onChange={props.onChange}
        onClick={onComboBoxFocus}
        componentRef={comboBox}
        errorMessage={props.errorMessage}
        onItemClick={props.onChange}
        persistMenu={props.persistMenu}
        autoComplete={props.autoComplete}
        allowFreeform={props.allowFreeform}
        openOnKeyboardFocus={props.openOnKeyboardFocus}
        scrollSelectedToTop={props.scrollSelectedToTop}
        text={optionText}
        useComboBoxAsMenuWidth={props.useComboBoxAsMenuWidth}
        data-testid={'selectbox'}
        caretDownButtonStyles={caretDownButtonStyle}
        label={props.label}
        selectedKey={props.selectedKey}
        iconButtonProps={props.iconButtonProps}
        onRenderLabel={
          props.IconLabel
            ? () => {
                return props.IconLabel
                  ? IconLabelRenderer({
                      title: props.IconLabel.title,
                      icon: props.IconLabel.icon,
                      label: props.IconLabel.label,
                    })
                  : null;
              }
            : undefined
        }
      />
    </div>
  );
}
