import {ShellStyles} from '@employee-experience/shell/lib/Components/Shell/Shell.styled';
import {FC, Fragment} from 'react';
import {createGlobalStyle} from 'styled-components';
import {SiteHeaderId} from '../SiteHeader/SiteHeader';

const AdditionalShellStyles = createGlobalStyle`
    #${SiteHeaderId} > div {
        position: unset;
    }
    body {
        padding-top: 50px;
        color: white;
    }
`;

export const SiteStyles: FC = () => {
  return (
    <Fragment>
      <ShellStyles />
      <AdditionalShellStyles />
    </Fragment>
  );
};
