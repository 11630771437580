/* eslint-disable import/no-anonymous-default-export */
export default {
  REACT_APP_MSAL_AUTHORITY_URL: 'https://login.microsoftonline.com/',
  REACT_APP_MSAL_CLIENT_ID: '2522238f-702c-4634-8bc8-44a2d57a7c65',
  REACT_APP_MSAL_TENANT_ID: '72f988bf-86f1-41af-91ab-2d7cd011db47',
  REACT_APP_MSAL_REDIRECT_URL: 'http://localhost:3000',
  REACT_APP_MSAL_SMART_PARKING_SCOPE_URL: 'https://microsoft.onmicrosoft.com/smartParkingAPINonProd/.default',
  REACT_APP_MSAL_GRAPH_ENDPOINT_URL: 'https://graph.microsoft.com/v1.0/me',
  REACT_APP_MSAL_ADMIN_ROLES: ['Parking.Admin'],
  REACT_APP_API_SMARTPARKING_APIM_KEY: '498bdbd4c827425fadf6e32266b3e2d8;product=smart-parking',
  REACT_APP_API_SMARTPARKING_APIM_BASEURL: 'https://dwp-nonprod.azure-api.net',
  REACT_APP_API_SMARTPARKING_ENVIRONMENT: 'ppe',
  GENERATE_SOURCEMAP: false,
};
