import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IMessageBadgeUsageEvents {
  dismissButtonClicked: UserEvent;
  root: string;
}

export const MessageBadgeUsageEvents: IMessageBadgeUsageEvents = {
  dismissButtonClicked: {
    subFeature: 'SPark.MessageBadge.dismiss',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  root: 'SPark.Registration.NavigationPanel',
};
