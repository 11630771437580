import {IStyle, mergeStyleSets, Depths} from '@fluentui/react';
import {SParkTheme} from '../../theme';

export const mainPanelStyles = mergeStyleSets({
  root: {
    background: SParkTheme.palette?.white,
    minHeight: '100vh',
    alignContent: 'center',
    paddingBottom: 35,
  } as IStyle,

  registerHeadingFontStyles: {
    fontSize: 22,
    fontWeight: 300,
    marginLeft: '1%',
  } as IStyle,

  primaryButtonStyle: {
    marginTop: '20px',
    marginLeft: '1%',
    marginRight: '20%',
    marginBottom: '20px',
    padding: 20,
    fontFamily: `${SParkTheme.fonts.xxLarge}`,
  } as IStyle,

  registerDivStyle: {
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
  } as IStyle,

  stackItemStyles: {
    background: SParkTheme.palette?.white,
    padding: 10,
    paddingBottom: 50,
    boxShadow: Depths.depth16,
    borderBottom: `2px solid ${SParkTheme.palette?.themeLight}`,
    borderTop: `2px solid ${SParkTheme.palette?.themeLight}`,
  } as IStyle,
});
