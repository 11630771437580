import React from 'react';
import {CoherenceHeader} from '@coherence-design-system/controls';
import {SiteHeaderLabel} from './SiteHeaderLabel';
import {siteHeaderStyles} from './SiteHeader.styles';
import {siteHeaderProps} from '../../../configs';

export const SiteHeaderId = siteHeaderProps.SiteHeaderId;
export const SiteHeader: React.FunctionComponent = () => {
  const {root, skipToMainStyles} = siteHeaderStyles;
  return (
    <div className={root} id={SiteHeaderId}>
      <CoherenceHeader
        headerLabel={siteHeaderProps.headerLabel}
        appNameSettings={{
          label: <SiteHeaderLabel />,
          ariaLabel: siteHeaderProps.ariaLabel,
        }}
        styles={{
          skipToMainLinkStyles: {
            skipToMainStyles,
          },
        }}
      />
    </div>
  );
};
