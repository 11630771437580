import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IAdminScreenUsageEvents {
  root: string;
  searchByAlias: UserEvent;
  searchByLicense: UserEvent;
  searchByPermit: UserEvent;
}

export const AdminScreenUsageEvents: IAdminScreenUsageEvents = {
  root: 'SPark.Registration.AdminScreen',
  searchByAlias: {
    subFeature: 'SPark.Registration.AdminScreen.SearchByAlias',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  searchByLicense: {
    subFeature: 'SPark.Registration.AdminScreen.SearchByLicense',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  searchByPermit: {
    subFeature: 'SPark.Registration.AdminScreen.SearchByPermit',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
};
