import React from 'react';
import {Stack, Link as LinkRedirect, Link} from '@fluentui/react';
import {adminLinkRouteStyles, INavigationPanelProps, userVehicleLinkRouteStyles, navigationPanelStyles} from '.';
import {FullWidthContainer, GridStyle} from '../../components';
import {ConfigKey, NavigationPanelConst} from '../../configs';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  setCarpoolError,
  setInfoBadgeState,
  setRegisterFormState,
  setVehicleDetails,
  vehicleRegistrationInitialState,
  setVehicleRegisterError,
  carpoolInitialState,
  resetCarpool,
  useGetConfigQuery,
  regionId,
  IUser,
  RootState,
} from '@microsoft/smart-parking-coreui';

export const NavigationPanel: React.FunctionComponent<INavigationPanelProps> = ({isAdminEnabled}) => {
  const {
    root,
    stackItemStyles,
    mainHeadingStyles,
    mainHeadingFontStyles,
    linkMyVehicleStyles,
    divStylesLink,
    divStylesRedirect,
    linkStylesOut,
    userDetailsStyles,
  } = navigationPanelStyles;
  const user: IUser = useSelector((state: RootState) => state.user);
  const userFirstName = user.firstName;
  const userLastName = user.lastName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const faqResourceHref = configData[ConfigKey.FaqResourceHref] as string;

  const resetState = () => {
    dispatch(setRegisterFormState({isFormEdit: false, isAnyFieldEdited: false}));
    dispatch(setVehicleDetails(vehicleRegistrationInitialState.initialRegisterVehicleState));
    dispatch(resetCarpool(carpoolInitialState.initialCarpoolState));
    dispatch(setInfoBadgeState(vehicleRegistrationInitialState.initialInfoBadgeResponseState));
    dispatch(setCarpoolError({isCarpoolError: false}));
    dispatch(
      setVehicleRegisterError({
        isFormSubmit: false,
        isVehicleTypeError: false,
        isMakeError: false,
        isModelError: false,
        isYearError: false,
        isColorError: false,
        isLicensePlateError: false,
        isStateError: false,
        isRegistrationTypeError: false,
        isParkingLocationError: false,
        isPolicyCheckedError: false,
        closeModal: false,
        isFirstNameError: false,
        isLastNameError: false,
      }),
    );
  };

  const handleAdminRoute = () => {
    navigate('/admin');
    resetState();
  };
  const handleMyVehicleRoute = () => {
    navigate('/');
    resetState();
  };

  return (
    <Stack className={root}>
      <GridStyle gridGap={6.4}>
        <FullWidthContainer>
          <div className={mainHeadingStyles}>
            <h1 className={mainHeadingFontStyles}> {NavigationPanelConst.header.headertext}</h1>
            <p className={userDetailsStyles}>
              Hi {userFirstName} {userLastName}!
            </p>
          </div>
          <Stack.Item align="auto" className={stackItemStyles}>
            <div className={divStylesLink} role="tablist">
              {isAdminEnabled ? (
                <>
                  <Link
                    className={userVehicleLinkRouteStyles(location.pathname)}
                    onClick={handleMyVehicleRoute}
                    aria-label="Navigate to my vehicles"
                    aria-selected={location.pathname === '/'}
                    role="tab">
                    {NavigationPanelConst.routeNames.userRoute}
                  </Link>
                  <Link
                    className={adminLinkRouteStyles(location.pathname)}
                    onClick={handleAdminRoute}
                    aria-label="navigate to admin"
                    aria-selected={location.pathname === '/admin'}
                    role="tab">
                    {NavigationPanelConst.routeNames.adminRoute}
                  </Link>
                </>
              ) : (
                <div className={linkMyVehicleStyles} role="tab">{NavigationPanelConst.routeNames.userRoute}</div>
              )}
            </div>
            <div className={divStylesRedirect}>
              <LinkRedirect className={linkStylesOut} href={faqResourceHref} target="_blank" underline>
                {NavigationPanelConst.routeNames.resourceFAQLinkText}
              </LinkRedirect>
            </div>
          </Stack.Item>
        </FullWidthContainer>
      </GridStyle>
    </Stack>
  );
};
