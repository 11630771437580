import {classNamesFunction, getTheme, Image} from '@fluentui/react';
import {FullWidthContainer, GridStyle} from '../../';
import {ISiteFooterProps, ISiteFooterStyles, ISiteFooterStyleProps, footerImageProps} from '.';
import {ISParkTheme} from '../../../theme';
import {siteFooterText} from '../../../configs';

const getClassNames = classNamesFunction<ISiteFooterStyleProps, ISiteFooterStyles>();

export function SiteFooterBase(props: ISiteFooterProps): JSX.Element {
  const classNames = getClassNames(props.styles, {theme: props.theme ?? (getTheme() as ISParkTheme)});

  return (
    <div role="contentinfo" className={classNames.root}>
      <GridStyle>
        <FullWidthContainer>
          <div className={classNames.ImageStyle}>
            <Image {...footerImageProps} alt={siteFooterText.imageAlt} />
          </div>
        </FullWidthContainer>
      </GridStyle>
    </div>
  );
}
