import {IStyle} from '@fluentui/react';
import {ISiteFooterStyleProps, ISiteFooterStyles, IFooterImageProps} from '.';
import {footerLogo} from '../../../assets';

export const siteFooterStyles = (props: ISiteFooterStyleProps): ISiteFooterStyles => {
  const root: IStyle = {
    bottom: 0,
    fontSize: props.theme.fonts.medium.fontSize,
    fontWeight: props.theme.fonts.medium.fontWeight,
    lineHeight: 40,
    backgroundColor: props.theme.palette.themePrimary,
    borderBottom: `1px solid ${props.theme.palette.themePrimary}`,
    zIndex: 10,
    height: 60,
  };
  const container: IStyle = {
    bottom: 0,
    fontSize: props.theme.fonts.medium.fontSize,
    fontWeight: props.theme.fonts.medium.fontWeight,
    lineHeight: 20,
    backgroundColor: props.theme.palette.themePrimary,
    zIndex: 10,
    height: 15,
    paddingTop: 5,
    paddingBottom: 25,
    paddingLeft: 100,
  };
  const link: IStyle = {
    paddingRight: 36,
    lineHeight: 20,
    fontSize: props.theme.fonts.medium.fontSize,
    color: props.theme.semanticColors.link,
    src: footerLogo,
    'a:hover': {
      color: `${props.theme.semanticColors.link}`,
    },
    'a:active:hover': {
      color: `${props.theme.semanticColors.link}`,
    },
  };
  const ImageStyle: IStyle = {
    lineHeight: 20,
    fontSize: props.theme.fonts.medium.fontSize,
    float: 'left',
    paddingLeft: '8%',
    paddingTop: '1%',
    color: props.theme.palette.themeSecondary,
  };

  return {root, link, container, ImageStyle};
};

export const footerImageProps: IFooterImageProps = {
  src: footerLogo,
};
