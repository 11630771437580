import {
  FontSizes,
  getScreenSelector,
  IIconProps,
  IImageProps,
  IStyle,
  mergeStyleSets,
  ScreenWidthMaxLarge,
  ScreenWidthMaxMedium,
  ScreenWidthMaxSmall,
  ScreenWidthMaxXLarge,
  ScreenWidthMinLarge,
  ScreenWidthMinMedium,
  ScreenWidthMinSmall,
  ScreenWidthMinXLarge,
} from '@fluentui/react';
import {SParkTheme} from '../../../theme';
import {cancelLogo, successLogo} from '../../../assets';

export const infoBadgeStyles = mergeStyleSets({
  messageStyle: {
    backgroundColor: SParkTheme.palette?.green,
    paddingLeft: '1%',
  } as IStyle,
  messageStyleError: {
    backgroundColor: SParkTheme.palette?.red,
    paddingLeft: '1%',
  } as IStyle,
  labelDivStyle: {
    paddingLeft: '35px',
    fontSize: FontSizes.size20,
    color: SParkTheme.palette?.white,
    paddingTop: 10,
  } as IStyle,
  childDiv: {
    marginTop: 10,
    display: 'flex',
    marginLeft: '19%',
    justifyContent: 'space-evenly',
    lineHeight: '14px',
    width: '100%',
  } as IStyle,
  imageStyle: {
    width: '90px',
    paddingRight: '20px',
  } as IStyle,
  closeImageStyle: {
    width: '90px',
    marginLeft: '450px',
    position: 'relative',
    marginTop: '10px',
    cursor: 'pointer',
    selectors: {
      [getScreenSelector(ScreenWidthMinSmall, ScreenWidthMaxSmall)]: {
        marginLeft: '8%',
      },
      [getScreenSelector(ScreenWidthMinMedium, ScreenWidthMaxMedium)]: {
        marginLeft: '8%',
      },
      [getScreenSelector(ScreenWidthMinLarge, ScreenWidthMaxLarge)]: {
        marginLeft: '8%',
      },
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginLeft: '380px',
      },
    },
  } as IStyle,
  errorHeadingStyle: {
    color: SParkTheme.palette?.white,
    fontSize: FontSizes.size20,
    fontFamily: 'Segoe UI Light',
    marginBottom: 10,
    lineHeight: '20px',
    width: '100%',
  } as IStyle,
});
export const hiddeniconPropsStyle: IIconProps = {
  'aria-hidden': true,
};
export const leftIconProps: IImageProps = {
  src: successLogo,
  alt: 'Success Logo',
};
export const closeIconProps: IImageProps = {
  src: cancelLogo,
  alt: 'Close Icon',
};
