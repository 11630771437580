import {IStackTokens, IStyle, mergeStyleSets} from '@fluentui/react';
import {SParkTheme} from '../../../theme';

export const IconLabelStyles = mergeStyleSets({
  root: {
    marginBottom: -3,
    color: SParkTheme.palette?.blueLight,
    marginTop: 0,
    height: 20,
    fontSize: 16,
    cursor: 'pointer',
  } as IStyle,
  labelStyle: {
    fontWeight: 100,
  } as IStyle,
});

export const stackTokens: IStackTokens = {
  childrenGap: 4,
};
