import {IStyle, IStackItemStyles, mergeStyleSets, Depths} from '@fluentui/react';
import {SParkTheme} from '../../theme';

export const EditVehicleDetailsStyles = mergeStyleSets({
  root: {
    background: SParkTheme.palette?.white,
    minHeight: '100vh',
    alignContent: 'center',
    paddingBottom: 35,
  } as IStyle,
  defaultButtonStyles: {
    margin: '20px 0px 0px 75px',
    padding: 20,
    marginLeft: '1%',
    marginRight: '5%',
    fontFamily: `${SParkTheme.fonts.xxLarge}`,
  } as IStyle,
  primaryButtonStyles: {
    margin: '20px 0px 20px 0px',
    padding: 20,
    fontFamily: `${SParkTheme.fonts.xxLarge}`,
  } as IStyle,
  editDivStyles: {
    paddingTop: 20,
    marginLeft: '1%',
  } as IStyle,
  editHeaderStyles: {
    fontSize: 30,
    fontWeight: 500,
  } as IStyle,
});
export const stackItemStyles: IStackItemStyles = {
  root: {
    background: SParkTheme.palette?.white,
    padding: 10,
    paddingBottom: 35,
    boxShadow: Depths.depth16,
    borderBottom: `2px solid ${SParkTheme.palette?.themeLight}`,
    borderTop: `2px solid ${SParkTheme.palette?.themeLight}`,
  },
};
