interface IPageUsageEvents {
  root: string;
  editRegistration: string;
  auth: string;
}

export const PageUsageEvents: IPageUsageEvents = {
  root: 'SPark.Registration',
  editRegistration: 'SPark.Registration.Edit',
  auth: 'SPark.Auth',
};
