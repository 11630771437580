import {
  IImageProps,
  IStyle,
  mergeStyleSets,
  getScreenSelector,
  ScreenWidthMinXLarge,
  ScreenWidthMaxXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMaxXXLarge,
} from '@fluentui/react';
import {SParkTheme} from '../../../theme';
import {headerLogo} from '../../../assets';

export const siteHeaderStyles = mergeStyleSets({
  root: {
    backgroundColor: SParkTheme.palette?.themePrimary,
    display: 'grid',
    gridTemplateColumns: '1000px 5fr',
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '60px',
    zIndex: 100,
  } as IStyle,

  siteHeaderImage: {
    display: 'flex',
    position: 'fixed',
    marginTop: 11,
    marginLeft: 50,
    selectors: {
      [getScreenSelector(ScreenWidthMinXLarge, ScreenWidthMaxXLarge)]: {
        marginLeft: 100,
      },
      [getScreenSelector(ScreenWidthMinXXLarge, ScreenWidthMaxXXLarge)]: {
        marginLeft: 230,
      },
      '@media(max-width: 1024px)': {
        marginLeft: 100,
      },
      '@media(max-width: 1440px)': {
        marginLeft: 146,
      },
      '@media(max-width: 320px)': {
        marginLeft: 10,
      },
      '@media(max-width: 375px)': {
        marginLeft: 10,
      },
      '@media(max-width: 425px)': {
        marginLeft: 10,
      },
    },
  } as IStyle,
  textStyle: {
    color: SParkTheme.palette?.white,
    margin: '10px 5px 5px 20px',
    fontSize: '180%',
    opacity: 1,
    fontWeight: 400,
    width: '200px',
    fontFamily: 'Segoe UI Semibold',
  },
  skipToMainStyles: {
    '@media(max-width: 1300px)': {
      textAlign: 'left',
      width: 'auto',
      padding: '0 2% 0 2%',
    },
  },
});

export const imageProps: IImageProps = {
  src: headerLogo,
};
