import {awarenessSectionStyles, myHubAndroidQrImageProps, myHubIosQrImageProps} from '.';
import {myHubAwarenessSectionText} from '../../../configs';
import {Image} from '@fluentui/react';

export const MyHubAwareness = (): JSX.Element => {
  return (
    <div className={awarenessSectionStyles.root}>
      <div className={awarenessSectionStyles.textSection}>{myHubAwarenessSectionText.label}</div>
      <div className={awarenessSectionStyles.qrCodeSection}>
        <div>
          <p className={awarenessSectionStyles.headingTag}>Android</p>
          <Image {...myHubAndroidQrImageProps} />
        </div>
        <div>
          <p className={awarenessSectionStyles.headingTag}>iOS</p>
          <Image {...myHubIosQrImageProps} />
        </div>
      </div>
    </div>
  );
};