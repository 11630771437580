import {EventType, UsageEventName, UserEvent} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IDataGridUsageEvents {
  root: string;
  SendCarpoolDeleteEmailSuccess: UserEvent;
  SendCarpoolDeleteEmailFailure: UserEvent;
  DeleteRegistrationOK: UserEvent;
  DeleteRegistrationSuccess: UserEvent;
  DeleteRegistrationFailure: UserEvent;
  DeleteRegistrationCancel: UserEvent;
  DeleteCarpoolRegistrationSuccess: UserEvent;
  DeleteCarpoolRegistrationFailure: UserEvent;
}

export const DataGridUsageEvents: IDataGridUsageEvents = {
  root: 'SPark.Registration.DataGrid',
  DeleteRegistrationOK: {
    subFeature: 'SPark.Registration.Delete.OK',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  DeleteRegistrationSuccess: {
    subFeature: 'SPark.Registration.Delete.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DeleteRegistrationFailure: {
    subFeature: 'SPark.Registration.Delete.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DeleteRegistrationCancel: {
    subFeature: 'SPark.Registration.Delete.Cancel',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  SendCarpoolDeleteEmailSuccess: {
    subFeature: 'SPark.Registration.Carpool.Delete.Email.Success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  SendCarpoolDeleteEmailFailure: {
    subFeature: 'SPark.Registration.Carpool.Delete.Email.Failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DeleteCarpoolRegistrationSuccess: {
    subFeature: 'SPark.Registration.Carpool.DeleteRegistration.Success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DeleteCarpoolRegistrationFailure: {
    subFeature: 'SPark.Registration.Carpool.DeleteRegistration.Failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
};
