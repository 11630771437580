import React from 'react';
import {MsalProvider} from '@azure/msal-react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {mergeStyles} from '@fluentui/react';
import {App} from './app/App';
import MsalAuthProvider from './authentication/MsalAuthProvider';
import {authHelper, store} from '@microsoft/smart-parking-coreui';
import Config from './configs/constants/uiConfig';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider
      instance={authHelper.getMsalInstance(
        Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
        Config.REACT_APP_MSAL_CLIENT_ID,
      )}>
      <MsalAuthProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalAuthProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
