import {Checkbox, Link} from '@fluentui/react';
import {FC} from 'react';
import {checkBoxStyles} from '.';
import {ConfigKey, carpool} from '../../../../configs';
import {useDispatch, useSelector} from 'react-redux';
import {
  ICarpoolData,
  IFormState,
  RootState,
  regionId,
  setCarpool,
  setRegisterFormState,
  useGetConfigQuery,
} from '@microsoft/smart-parking-coreui';

export const CarpoolPolicyCheck: FC = () => {
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const editFormState: IFormState = useSelector((state: RootState) => state.formState);
  const dispatch = useDispatch();
  const {data: configData = {}} = useGetConfigQuery(regionId);
  const CarpoolPolicyLinkHref = configData[ConfigKey.CarpoolPolicyLinkHref] as string;

  const onCheckChange = (event: any) => {
    const checked = event.target.checked;
    dispatch(setCarpool({...carpoolDetails, carpoolPolicyChecked: checked}));
    dispatch(setRegisterFormState({...editFormState, isAnyFieldEdited: true}));
  };

  const renderLabelWithLink = () => {
    return (
      <span>
        {carpool.carpoolPolicyTexts.carpoolPolicyText1}
        <Link href={CarpoolPolicyLinkHref} underline target="_blank">
          {carpool.carpoolPolicyTexts.carpoolPolicyText2}
        </Link>
      </span>
    );
  };

  return (
    <Checkbox
      styles={checkBoxStyles}
      data-testid="carpoolPolicyCheck"
      onRenderLabel={renderLabelWithLink}
      onChange={event => onCheckChange(event)}
      checked={carpoolDetails.carpoolPolicyChecked}
    />
  );
};
