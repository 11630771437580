import {IStyle, mergeStyleSets} from '@fluentui/react';

export const divGap = mergeStyleSets({
  root: {
    padding: 5,
  } as IStyle,
});

export const divRightAllign = mergeStyleSets({
  root: {
    padding: 5,
    float: 'right',
  } as IStyle,
});
