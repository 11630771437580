import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface ICarpoolMainUsageEvents {
  CarpoolGroupMemberCheckbox: UserEvent;
  AddCarpoolGroupMember: UserEvent;
  root: string;
}

export const CarpoolMainUsageEvents: ICarpoolMainUsageEvents = {
  CarpoolGroupMemberCheckbox: {
    subFeature: 'SPark.Registration.Carpool.Main.GroupMember.Checkbox.onChange',
    type: EventType.User,
    eventName: UsageEventName.CheckBoxChanged,
  },
  AddCarpoolGroupMember: {
    subFeature: 'SPark.Registration.Carpool.Main.GroupMember.Add',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  root: 'SPark.Registration.Carpool.Main',
};
