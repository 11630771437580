import React, {useEffect} from 'react';
import {Stack} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {InputField} from '../../common';
import {ownerDivStyle, ownerStackTokens} from '.';
import {ownerDetailsConst, emptyString, EmptySpaceString, TelemetryConstants} from '../../../configs';
import {OwnerDetailsUsageEvents} from '../../../configs/usageEvents';
import {isRegistrationTypeSponsored, noSpecialCharacterAndSpaces} from '../../../utils';
import Config, {vehicleDetail} from '../../../configs/constants/uiConfig';
import {
  RootState,
  IRegisterVehicleErrorItem,
  getTelemetryClient,
  setVehicleDetails,
  setRegisterFormState,
  setVehicleRegisterError,
} from '@microsoft/smart-parking-coreui';

export const OwnerDetails = (): JSX.Element => {
  const {root} = ownerDivStyle;
  const vehicleFormFields = useSelector((state: RootState) => state.vehicleDetails);
  const vehicleFormError: IRegisterVehicleErrorItem = useSelector((state: RootState) => state.registerVehicleError);
  const dispatch = useDispatch();
  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const isFormEdit = useSelector((state: RootState) => state.formState.isFormEdit);

  /* istanbul ignore next */
  useEffect(() => {
    telemetryClient.startTrackPage(OwnerDetailsUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(OwnerDetailsUsageEvents.root);
    };
  }, [telemetryClient]);

  const handleInputChange = (event: any, fieldName: any) => {
    event.preventDefault();
    dispatch(setRegisterFormState({isFormEdit: isFormEdit, isAnyFieldEdited: true}));
    noSpecialCharacterAndSpaces(event.target.value) &&
      dispatch(setVehicleDetails({...vehicleFormFields, [fieldName]: event.target.value}));
  };

  return (
    <>
      <Stack horizontal className={root} tokens={ownerStackTokens}>
        <InputField
          inputType="text"
          name={vehicleDetail.vehicleOwnerFirstName.name}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.vehicleOwnerFirstName.name);
            vehicleFormError.isFirstNameError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isFirstNameError: false}));
          }}
          placeholder={ownerDetailsConst.ownerFirstName.placeHolder}
          value={vehicleFormFields.ownerFirstName}
          required={true}
          IconLabel={{
            title: emptyString,
            icon: emptyString,
            label: ownerDetailsConst.ownerFirstName.label,
          }}
          validateOnLoad={false}
          errorMessage={
            vehicleFormError.isFormSubmit && !vehicleFormFields.ownerFirstName ? EmptySpaceString : emptyString
          }
          disabled={!isRegistrationTypeSponsored(vehicleFormFields.registrationType)}
          styles={{root: {display: !isRegistrationTypeSponsored(vehicleFormFields.registrationType) ? 'none' : 'inline-block'}}}
        />
        <InputField
          inputType="text"
          name={vehicleDetail.vehicleOwnerLastName.name}
          onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleInputChange(event, vehicleDetail.vehicleOwnerLastName.name);
            vehicleFormError.isLastNameError &&
              dispatch(setVehicleRegisterError({...vehicleFormError, isLastNameError: false}));
          }}
          placeholder={ownerDetailsConst.ownerLastName.placeHolder}
          value={vehicleFormFields.ownerLastName}
          required={true}
          IconLabel={{
            title: emptyString,
            icon: emptyString,
            label: ownerDetailsConst.ownerLastName.label,
          }}
          validateOnLoad={false}
          errorMessage={
            vehicleFormError.isFormSubmit && !vehicleFormFields.ownerLastName ? EmptySpaceString : emptyString
          }
          disabled={!isRegistrationTypeSponsored(vehicleFormFields.registrationType)}
          styles={{root: {display: !isRegistrationTypeSponsored(vehicleFormFields.registrationType) ? 'none' : 'inline-block'}}}
        />
      </Stack>
    </>
  );
};
