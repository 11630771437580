import {IconButton, Text} from '@fluentui/react';
import {AddGroupButtonStyle} from '.';
import {carpool} from '../../../../configs';

export const AddGroupButton = ({handleAddGroup, disabled}: any) => {
  const {textStyle, iconButtonStyle} = AddGroupButtonStyle;

  return (
    <IconButton
      iconProps={{iconName: carpool.addGroupButtonText.iconName}}
      title={carpool.addGroupButtonText.title}
      ariaLabel={carpool.addGroupButtonText.ariaLabel}
      onClick={handleAddGroup}
      disabled={disabled}
      className={iconButtonStyle}>
      <Text className={textStyle} variant="medium">
        {carpool.addGroupButtonText.label}
      </Text>
    </IconButton>
  );
};
