import {FontSizes, IIconProps, IImageProps, IStyle, mergeStyleSets} from '@fluentui/react';
import {SParkTheme} from '../../../theme';
import {validateLogo} from '../../../assets/';

export const errorBadgeStyles = mergeStyleSets({
  messageStyle: {
    backgroundColor: SParkTheme.palette?.red,
  } as IStyle,
  labelDivStyle: {
    fontSize: FontSizes.size20,
    lineHeight: '25px',
    color: SParkTheme.palette?.white,
  } as IStyle,
  labelChildStyle: {
    fontSize: FontSizes.size20,
    fontFamily: 'Segoe UI Light',
    color: SParkTheme.palette?.white,
    padding: 0,
  } as IStyle,
  labelPolicyStyle: {
    fontSize: FontSizes.size20,
    fontFamily: 'Segoe UI Light',
    color: SParkTheme.palette?.white,
    padding: 0,
  } as IStyle,
  childDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
  } as IStyle,
  imageStyle: {
    width: '50px',
    paddingRight: '20px',
  } as IStyle,
  closeImageStyle: {
    ':active, :focus, :hover': {
      backgroundColor: 'red',
      color: 'white',
    },
    fontSize: 35,
    height: 35,
    width: 35,
    color: 'white',
    float: 'right',
    right: 0,
    position: 'absolute',
  } as IStyle,
  errorHeadingStyle: {
    color: SParkTheme.palette?.white,
    fontSize: FontSizes.size20,
    fontFamily: 'Segoe UI Light',
  } as IStyle,
  iconStyle: {
    fontSize: 35,
    height: 35,
    width: 35,
    margin: 20,
    color: 'white',
  } as IStyle,
  mainDivStyle: {backgroundColor: SParkTheme.palette?.red, paddingTop: '15px'} as IStyle,
});
export const iconPropsStyle: IIconProps = {
  'aria-hidden': true,
};
export const validateIconProps: IImageProps = {
  src: validateLogo,
  alt: 'Validation Failure Logo',
};
