import {UserEvent, UsageEventName, EventType, UsageEvent} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IEditRegistrationUsageEvents {
  Back: UserEvent;
  Save: UserEvent;
  EditRegistration: UserEvent;
  EditRegistrationSuccess: UserEvent;
  EditRegistrationFailure: UserEvent;
  DuplicateRegistrationFailure: UsageEvent;
  RegistrationFormValidationSuccess: string;
  RegistrationFormValidationFailure: string;
}

export const EditRegistrationUsageEvents: IEditRegistrationUsageEvents = {
  Back: {
    subFeature: 'SPark.Registration.Edit.Back',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  Save: {
    subFeature: 'SPark.Registration.Edit.Save',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  EditRegistrationSuccess: {
    subFeature: 'SPark.Registration.Edit.success',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  EditRegistrationFailure: {
    subFeature: 'SPark.Registration.Edit.failure',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  DuplicateRegistrationFailure: {
    subFeature: 'SPark.Registration.Edit.duplicate',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  EditRegistration: {
    subFeature: 'SPark.Registration.Edit',
    type: EventType.User,
    eventName: UsageEventName.ButtonClicked,
  },
  RegistrationFormValidationSuccess: 'SPark.Registration.Edit.Validation.success',
  RegistrationFormValidationFailure: 'SPark.Registration.Edit.Validation.failure',
};
