import {UserEvent, UsageEventName, EventType} from '@employee-experience/common/lib/Models/UsageTelemetry';

interface IAuthUtilUsageEvents {
  interactionRequiredAuthError: UserEvent;
  acquireTokenSilent: UserEvent;
  loginRedirect: UserEvent;
  getUserAlias: UserEvent;
}

export const AuthUtilUsageEvents: IAuthUtilUsageEvents = {
  interactionRequiredAuthError: {
    subFeature: 'SPark.App.Auth.interactionRequiredAuthError',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  acquireTokenSilent: {
    subFeature: 'SPark.App.Auth.acquireTokenSilent',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  loginRedirect: {
    subFeature: 'SPark.App.Auth.loginRedirect',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
  getUserAlias: {
    subFeature: 'SPark.App.Auth.getUserAlias',
    type: EventType.User,
    eventName: UsageEventName.BackEndAPICall,
  },
};
