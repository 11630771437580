import {DefaultButton, Dialog, DialogFooter, IconButton, PrimaryButton} from '@fluentui/react';
import {useMemo} from 'react';
import {useId, useBoolean} from '@fluentui/react-hooks';
import {carpool, deleteCarpoolDialogProps, dialogDragOptions, dialogtest} from '../../../../configs';
import {CarpoolDeleteDialogstyles, dialogContentStyles, dialogStyles} from '.';
import {useDispatch, useSelector} from 'react-redux';
import {ICarpoolData, RootState, ICarpoolMember, setCarpool} from '@microsoft/smart-parking-coreui';

export const CarpoolDeleteButton = (props: any) => {
  const {deleteIconStyle, divStyle, okButtonStyle} = CarpoolDeleteDialogstyles;
  const {index} = props;
  const dispatch = useDispatch();
  const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true);
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const onClickOk = () => {
    toggleHideDialog();
    handleRemovedItem();
  };
  const handleRemovedItem = () => {
    let updatedItem = carpoolDetails.carpoolMembers.filter(
      (_value: ICarpoolMember, itemIndex: number) => itemIndex !== index,
    );
    dispatch(
      setCarpool({
        ...carpoolDetails,
        carpoolMembers: updatedItem,
      }),
    );
  };
  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: dialogDragOptions,
    }),
    [labelId, subTextId],
  );

  return (
    <div>
      {index && index !== 0 ? (
        <IconButton
          className={deleteIconStyle}
          iconProps={{iconName: carpool.deleteButton.name}}
          title={carpool.deleteButton.title}
          ariaLabel={carpool.deleteButton.ariaLabel}
          onClick={toggleHideDialog}
        />
      ) : (
        <div className={divStyle}></div>
      )}
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={{
          ...deleteCarpoolDialogProps,
          styles: dialogContentStyles,
        }}
        modalProps={modalProps}>
        <DialogFooter>
          <>
            <PrimaryButton onClick={onClickOk} text={dialogtest.onClickOk} className={okButtonStyle} />
            <DefaultButton onClick={toggleHideDialog} text={dialogtest.onClickCancel} />
          </>
        </DialogFooter>
      </Dialog>
    </div>
  );
};
