import {IStyle, mergeStyleSets} from '@fluentui/react';

export const AddGroupButtonStyle = mergeStyleSets({
  textStyle: {
    alignContent: 'center',
    paddingTop: 3,
  } as IStyle,
  iconButtonStyle: {
    width: 165,
  } as IStyle,
});
