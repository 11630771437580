import {IStyle, mergeStyleSets} from '@fluentui/react';
import {dataGrid} from '../../../../configs';
import {SParkTheme} from '../../../../theme';

export const CarpoolDeleteDialogstyles = mergeStyleSets({
  deleteIconStyle: {
    marginTop: 30,
    color: SParkTheme.palette?.red,
    displayName: dataGrid.gridColumnActionConst.deleteAction.visibleActions.iconDisplayName,
    hover: dataGrid.gridColumnActionConst.deleteAction.visibleActions.hover,
  } as IStyle,
  divStyle: {
    width: 30,
  } as IStyle,
  okButtonStyle: {
    marginRight: '10px',
  } as IStyle,
});
export const dialogStyles = {main: {maxWidth: 450}};
export const dialogContentStyles = {title: {fontSize: 25}};
