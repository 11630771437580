import {IStyle, mergeStyleSets, ITextFieldStyles, ILabelStyleProps, ILabelStyles} from '@fluentui/react';

export const InputStyles = mergeStyleSets({
  root: {
    fontWeight: 100,
  } as IStyle,
});

export const getStyles = (isFieldDisabled: boolean): Partial<ITextFieldStyles> => {
  return {
    subComponentStyles: {
      label: getLabelStyles,
    },
    field: {fontWeight: isFieldDisabled ? 600 : 400, width: 188},
  };
};
function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
  const {required} = props;
  return {
    root: required && {
      fontWeight: 100,
    },
  };
}
