import {Checkbox, Stack, Separator, IComboBox} from '@fluentui/react';
import React, {useEffect, useMemo, useState} from 'react';
import {AddGroupButton, CarpoolMemberList, CarpoolPolicyCheck} from '..';
import {carpoolMainStyle} from './CarpoolMain.styles';
import {emptyString, TelemetryConstants} from '../../../../configs';
import {useDispatch, useSelector} from 'react-redux';
import {CarpoolMainUsageEvents} from '../../../../configs/usageEvents';
import {
  getTelemetryClient,
  ICarpoolData,
  IFormState,
  IRegisteredVehicleDataItem,
  RootState,
  setCarpool,
  setRegisterFormState,
  setVehicleDetails,
} from '@microsoft/smart-parking-coreui';
import Config, {MemberType, Motorcycle, carpool} from '../../../../configs/constants/uiConfig';
import {isRegistrationTypeVisitor} from '../../../../utils';

export const CarpoolMain = () => {
  const [showCarpoolCheckBox, setShowCarpoolCheckBox] = useState(true);
  const dispatch = useDispatch();
  const {checkboxStyle, addGroupDivStyle} = carpoolMainStyle;
  const carpoolDetails: ICarpoolData = useSelector((state: RootState) => state.carpool);
  const isEditFormState: IFormState = useSelector((state: RootState) => state.formState);
  const isEditPanel = isEditFormState.isFormEdit;
  const [isDisabled, setIsDisabled] = useState<Boolean>(true);
  const [carpoolMemberRefState, setCarpoolMemberRefState] = useState<React.RefObject<IComboBox>>();
  const vehicleDetails: IRegisteredVehicleDataItem = useSelector((state: RootState) => state.vehicleDetails);

  const telemetryClient = getTelemetryClient(
    Config.REACT_APP_API_SMARTPARKING_ENVIRONMENT,
    Config.REACT_APP_MSAL_CLIENT_ID,
    TelemetryConstants.metadata.AppName,
  );
  const logProperties = useMemo(
    () => ({showCarpoolCheckBox, isEditPanel, ...carpoolDetails, isFTE: isDisabled}),
    [carpoolDetails, isDisabled, isEditPanel, showCarpoolCheckBox],
  );

  useEffect(() => {
    let carpoolMemberList = carpoolDetails?.carpoolMembers;
    if (isEditPanel && vehicleDetails.carpoolStatus && showCarpoolCheckBox) {
      !vehicleDetails.carpoolStatus && dispatch(setVehicleDetails({...vehicleDetails, isCarpoolChecked: true}));
      setShowCarpoolCheckBox(true);
      logProperties.showCarpoolCheckBox = true;
    }
    if (
      carpoolMemberList.filter(value =>
        value.memberType === MemberType.ScoopEmployee
          ? value.email
          : value.memberType === MemberType.NonMsEmployee
          ? value.memberType && value.email && value.originCity
          : value.memberType && value.memberAlias && value.originCity,
      ).length === carpoolMemberList.length
    ) {
      setIsDisabled(false);
      logProperties.isFTE = false;
    } else {
      setIsDisabled(true);
      logProperties.isFTE = true;
    }
  }, [carpoolDetails, dispatch, isEditPanel, logProperties, showCarpoolCheckBox, vehicleDetails]);

  useEffect(() => {
    telemetryClient.startTrackPage(CarpoolMainUsageEvents.root);
    return () => {
      telemetryClient.stopTrackPage(CarpoolMainUsageEvents.root, undefined, logProperties);
    };
  }, [telemetryClient, logProperties]);

  const onChangeCheckbox = (event: any) => {
    telemetryClient.startTrackEvent(CarpoolMainUsageEvents.CarpoolGroupMemberCheckbox);
    const isChecked = event.target.checked;
    setShowCarpoolCheckBox(isChecked);
    dispatch(setVehicleDetails({...vehicleDetails, isCarpoolChecked: isChecked}));
    dispatch(setRegisterFormState({...isEditFormState, isAnyFieldEdited: true}));
    logProperties.showCarpoolCheckBox = isChecked;
    telemetryClient.stopTrackEvent(CarpoolMainUsageEvents.CarpoolGroupMemberCheckbox, logProperties);
  };

  const renderCheckboxLabel = () => {
    return (
      <span>
        {vehicleDetails.isCarpoolChecked
          ? carpool.carpoolCheckBoxConst.deleteCarpoollabel
          : carpool.carpoolCheckBoxConst.createCarpoollabel}
      </span>
    );
  };

  const handleAddGroup = () => {
    telemetryClient.startTrackEvent(CarpoolMainUsageEvents.AddCarpoolGroupMember);
    dispatch(
      setCarpool({
        ...carpoolDetails,
        carpoolMembers: [
          ...carpoolDetails.carpoolMembers,
          {
            memberType: emptyString,
            memberAlias: emptyString,
            originCity: emptyString,
            permitNumber: emptyString,
            email: emptyString,
          },
        ],
      }),
    );
    setCarpoolMemberRefState(React.createRef());

    telemetryClient.stopTrackEvent(CarpoolMainUsageEvents.AddCarpoolGroupMember, logProperties);
  };
  useEffect(() => {
    carpoolMemberRefState?.current?.focus(true);
  }, [carpoolMemberRefState]);

  return (
    <>
      {vehicleDetails.vehicleType !== Motorcycle && !isRegistrationTypeVisitor(vehicleDetails.registrationType) && (
        <Checkbox
          onRenderLabel={renderCheckboxLabel}
          onChange={event => onChangeCheckbox(event)}
          className={checkboxStyle}
          data-testid="carpoolCheckBox"
          checked={vehicleDetails.isCarpoolChecked}
        />
      )}
      {vehicleDetails.isCarpoolChecked && (
        <>
          <Separator />
          <CarpoolMemberList carpoolMemberAreaRef={carpoolMemberRefState} />
          <Stack horizontal className={addGroupDivStyle}>
            <AddGroupButton handleAddGroup={handleAddGroup} disabled={isDisabled} />
          </Stack>
          <CarpoolPolicyCheck />
          <Separator />
        </>
      )}
    </>
  );
};
