import {IStyle, IStackStyles} from '@fluentui/react';

export const checkBoxStyles: IStackStyles = {
  root: {
    alignItems: 'flex-start',
    marginBottom: '20px',
    marginLeft: '1%',
    marginRight: '20%',
  } as IStyle,
};
