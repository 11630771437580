import {IStyle, mergeStyleSets} from '@fluentui/react';

export const carpoolMainStyle = mergeStyleSets({
  checkboxStyle: {
    alignItems: 'flex-start',
    marginLeft: '1%',
    marginRight: '20%',
    marginTop: 10,
  } as IStyle,
  addGroupDivStyle: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'flex-start',
    marginLeft: '1%',
    marginRight: '5%',
    paddingBottom: 10,
  } as IStyle,
});
